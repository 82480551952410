import { validateOrderTripAddition } from '.'

import { EmptyArray } from 'lib/array/arrayUtils'
import isUpcoming, { isOrderCurrent } from 'lib/order/isUpcoming'
import { sortOrdersByDeparture } from 'lib/order/orderUtils'
import { FullTrip, TripSummaryEntry } from 'tripPlanner/types/common'
import { PublicMapItem, PublicTripItem } from 'tripPlanner/types/publicTrip'
import { ItemType, MapItem, TripItem } from 'tripPlanner/types/tripItem'

export const itemTypeToProductType: Record<
  ItemType,
  App.TripPlannerProductFilterType
> = {
  ACCOMMODATION: 'Stay',
  TOUR: 'Tour',
  CRUISE: 'Cruise',
  FLIGHT: 'Flight',
  CAR_RENTAL: 'Transport',
  TRAIN: 'Transport',
  FERRY: 'Transport',
  BUS: 'Transport',
  OTHER_TRANSPORT: 'Transport',
  EXPERIENCE: 'Thing to do',
  EVENT: 'Thing to do',
  THING_TO_DO: 'Thing to do',
  RESTAURANT_BAR: 'Restaurant',
  ATTRACTION: 'Thing to do',
  NOTE: 'Note',
}

const emptyProductFilterCounts: Record<
  App.TripPlannerProductFilterType,
  number
> = {
  Flight: 0,
  Stay: 0,
  'Thing to do': 0,
  Restaurant: 0,
  Tour: 0,
  Cruise: 0,
  Transport: 0,
  Note: 0,
}

const productCategoriesInOrder: Array<App.TripPlannerProductFilterType> = [
  'Stay',
  'Flight',
  'Thing to do',
  'Restaurant',
  'Transport',
  'Tour',
  'Cruise',
  'Note',
]

export interface TripPlannerFilterTypeCount {
  filterType: App.TripPlannerSubFilterType
  count: number
}

export const getAllProductFilters = (
  items: Array<TripItem> | Array<PublicTripItem> = [],
): Array<TripPlannerFilterTypeCount> => {
  const counts = { ...emptyProductFilterCounts }
  items.forEach((item) => {
    const productType = itemTypeToProductType[item.type]
    if (productType in counts) {
      counts[productType] += 1
    }
  })

  return productCategoriesInOrder
    .map((filterType) => ({
      filterType,
      count: counts[filterType],
    }))
    .sort((a, b) => b.count - a.count)
}

export const getNonEmptyProductFilters = (
  items: Array<TripItem> | Array<PublicTripItem> = [],
): Array<TripPlannerFilterTypeCount> => {
  return getAllProductFilters(items).filter(({ count }) => count > 0)
}

export function filterProducts<T extends Pick<TripItem, 'type'>>(
  item: T,
  filters: Set<App.TripPlannerSubFilterType>,
) {
  return filters.has(itemTypeToProductType[item.type])
}

export const filterTripSummary = (
  entries: Array<TripSummaryEntry> = EmptyArray,
  filters: Array<App.TripPlannerSubFilterType>,
): Array<TripSummaryEntry> => {
  if (entries.length === 0) {
    return entries
  }

  const filterSet = new Set(filters)

  const filteredItems = entries.filter(
    (entry) =>
      entry.type === 'ITEM' &&
      (filters.length === 0 || filterProducts(entry.item, filterSet)),
  )
  if (filteredItems.length > 0) {
    return filteredItems
  }

  // If there are no items, then show any recommendations for the filters
  // Currently we do this only for flight recommendations
  return entries.filter((entry) => {
    if (entry.type === 'ITEM') {
      return false
    } else if (entry.recommendation.type === 'FLIGHT') {
      return filterSet.has('Flight')
    }
    return false
  })
}

export const filterSummaryMapItems = (
  items: Array<MapItem | PublicMapItem>,
  filters: Array<App.TripPlannerSubFilterType>,
): Array<MapItem | PublicMapItem> => {
  if (filters.length > 0) {
    const filterSet = new Set(filters)
    return items.filter(
      (item) => filterProducts(item, filterSet),
    )
  }
  return items
}

export function filterTripItems<
  T extends Array<TripItem> | Array<PublicTripItem>,
>(items: T, filters: Array<App.TripPlannerSubFilterType>): T {
  if (filters.length > 0) {
    const filterSet = new Set(filters)
    // @ts-ignore Not sure how to deal with this type issue
    return items.filter(
      (item) => filterProducts(item, filterSet),
    )
  }
  return items
}

export function itemsFromSummaryEntries(
  tripSummaryEntries?: Array<TripSummaryEntry>,
): Array<TripItem> {
  return (
    tripSummaryEntries
      ?.filter(
        (i): i is Extract<TripSummaryEntry, { type: 'ITEM' }> =>
          i.type === 'ITEM',
      )
      .map((i) => i.item) ?? []
  )
}

export function getFutureFilteredOrdersNotInTrip(
  orders: Array<App.Order> = [],
  trip: FullTrip | undefined,
): Array<App.Order> {
  return sortOrdersByDeparture(
    orders.filter(
      (order) =>
        !trip?.orderIds?.includes(order.id) &&
        (isUpcoming(order) || isOrderCurrent(order)) &&
        validateOrderTripAddition(order),
    ),
  )
}
